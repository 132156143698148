html,
body,
#root {}

.range-container {
    display: flex;
    align-items: center;
}

.range-slider {
    width: 100%;
    margin: 0 5px;
}
